import './App.scss';
import logo from './assets/images/mm-logo.png';

function App() {
  return (
    <main className="app">
      <img src={logo} className="app__logo"  alt="mooing munchies logo" />
      <p className="app__desc">coming soon</p>
      <a href="mailto:orders@mooingmunchies.ca" className="app__link">
        send us a message
      </a>
    </main>
  )
}

export default App;